import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@storage/types';
import { Colors } from '@coreui/react-pro/dist/components/Types';

interface IToastHeader {
  text: string;
  closeButton?: boolean;
}

interface IToastBody {
  text: string;
}

interface IToastSettings {
  delay?: number;
  color?: Colors;
  header?: IToastHeader;
  body?: IToastBody
}

interface IToastState {
  settings?: IToastSettings;
}

const initialState: IToastState = {};

const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IToastSettings>) => {
      state.settings = action.payload;
    },
    reset: () => initialState
  }
});

const { set, reset } = slice.actions;

const toast = {
  set,
  setError: (text: string, delay: number = 3000) => set({
    delay,
    color: 'danger',
    header: {
      text,
      closeButton: true
    }
  }),
  reset,
  selectSettings: (state: RootState) => state.toast.settings
};

export const toastReducer = slice.reducer;
export default toast;
